import { useRouter } from "next/router";
import { useAuth } from '@/hooks/use-auth';
import { isArray } from "lodash";
import _ from 'lodash';
import { ExpressApplicantRoles, PosApplicantRoles } from '@/lib/user';

export function useRouteControl() {
  const router = useRouter();
  const { user } = useAuth();

  return {
    home: async (): Promise<boolean> => router.push('/'),
    auth: async (): Promise<boolean> => {
      if(user && isArray(user.groups) && !_(user.groups).intersection(ExpressApplicantRoles).isEmpty() === true)
        return router.push('/auth/express-application');
      else if(user && isArray(user.groups) && !_(user.groups).intersection(PosApplicantRoles).isEmpty() === true)
        return router.push('/auth/pos-application');

      return router.push('/auth');
    },
  }
}