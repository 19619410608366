import fetch from 'isomorphic-unfetch';
import { message, notification } from 'antd';
import * as Sentry from '@sentry/node';

interface APIResponse {
  isSuccessful: boolean;
}

export class APISuccess<T> implements APIResponse {
  isSuccessful: true = true;
  data: T = undefined;
  constructor(payload: T) {
    if (payload) this.data = Object.assign({}, payload);
  }
}
export class APIFailure implements APIResponse {
  isSuccessful: false = false;
  errorMessage: string;
  statusCode: number;
  shortCode: string;

  private safePrint(value: unknown): string {
    if (value === undefined) {
      return 'null response';
    } else if (typeof value == 'string') {
      return value;
    } else if (value instanceof Object && value['message'] !== undefined && value['error'] !== undefined && Array.isArray(value['error'])) {
      return this.safePrint(`${value['message']}: ${value['error'].filter(err => typeof err === 'string').join(",")}`); // Only show string error details 
    } else if (value instanceof Object && value['message'] !== undefined) {
      return this.safePrint(value['message']);
    } else {
      return JSON.stringify(value);
    }
  }

  constructor(payload: any, statusCode: number, tolerate?: boolean) {
    this.errorMessage = this.safePrint(payload);
    this.statusCode = statusCode;
    if (tolerate !== true)
      Sentry.captureException({
        statusCode,
        payload,
      });
    this.shortCode = new RegExp('^[A-Z0-9_]$').test(this.errorMessage) ? this.errorMessage : 'UNKNOWN_ERROR';
  }
}

export type APIResult<T> = APISuccess<T> | APIFailure;

type Header = Record<string, string>;
type APIMethod = 'GET' | 'POST' | 'DELETE' | 'PUT';
class APIRequest {
  private _url: string;
  private _method: string;
  private _headers: Header;
  private _body: BodyInit;
  private _errorMessage: string;

  constructor() {
    this._headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json;text/plain',
      'Accept-Charset': 'utf-8',
    };
    this._errorMessage = '';
  }

  private clone(): APIRequest {
    return Object.assign(new APIRequest(), this);
  }

  setAuthToken(accessToken: string): APIRequest {
    const instance = this.clone();
    Object.assign(instance._headers, {
      Authorization: `Bearer ${accessToken}`,
    });
    return instance;
  }

  setAPI(url: string): APIRequest {
    const instance = this.clone();
    instance._url = `/api/${url}`.replace('//', '/');
    return instance;
  }

  setUrl(url: string): APIRequest {
    const instance = this.clone();
    instance._url = url;
    return instance;
  }

  setMethod(method: APIMethod): APIRequest {
    const instance = this.clone();
    instance._method = method;
    return instance;
  }

  setHeaders(headers: Header): APIRequest {
    const instance = this.clone();
    instance._headers = { ...this._headers, ...headers };
    return instance;
  }

  setBody(body: BodyInit): APIRequest {
    const instance = this.clone();
    instance._body = body;
    return instance;
  }

  setData(body: object): APIRequest {
    const instance = this.clone();
    instance._body = JSON.stringify(body);
    return instance;
  }

  setErrorMessage(inputMessage: string): APIRequest {
    const instance = this.clone();
    instance._errorMessage = inputMessage;
    return instance;
  }

  async send(): Promise<Response> {
    return await fetch(this._url, {
      method: this._method,
      headers: new Headers(this._headers),
      body: this._body,
    });
  }

  async fetchData<T>(): Promise<T> {
    const resp = await this.send();
    const body = await resp.json();
    if (!resp.ok) {
      const error = new APIFailure(body, resp.status);
      notification.error({
        message: this._errorMessage,
        key: error.shortCode,
      });
      return;
    }
    return body.data;
  }

  async fetchResponse<T>(tolerate?: boolean): Promise<APIResult<T>> {
    const resp = await this.send();
    const body = await resp.json();
    if (!resp.ok) {
      const error = new APIFailure(body, resp.status, tolerate);
      if (tolerate !== true) message.error(this._errorMessage);
      return error;
    }
    return new APISuccess<T>(body?.data);
  }
}

export default APIRequest;
