import React from 'react';
import { BaseError, BaseErrorProps } from './base';
import { useTranslation } from 'react-i18next';

export type SimpleErrorProps = Partial<BaseErrorProps>;

export const NotSignedInError: React.FunctionComponent<SimpleErrorProps> = ({ title, redirectUrl = '/auth' }: SimpleErrorProps) => {
  const { t } = useTranslation();
  return <BaseError statusCode={403} title={title || t('common.error.pleasesignin')} redirectUrl={redirectUrl} />;
};

export const AccessDeniedError: React.FunctionComponent<SimpleErrorProps> = ({ title, ...props }: SimpleErrorProps) => (
  <BaseError {...props} statusCode={403} title={title} />
);

export const NotFoundError: React.FunctionComponent<SimpleErrorProps> = ({ title, ...props }: SimpleErrorProps) => (
  <BaseError {...props} statusCode={404} title={title} />
);
