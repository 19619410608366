import React, { createContext, useContext, PropsWithChildren, useCallback, useEffect } from 'react';
import { AuthClass } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';
import { useAsync, useMethods } from 'react-use';
import moment from 'moment';

import { RoleType, User, UserCredentials, SellerUserRoles } from '@/lib/user';
import { NextPage, NextPageContext } from 'next';
import Router from 'next/router';
import _ from 'lodash';
import { AccessDeniedError, NotSignedInError } from '@components/errors';
import { Spin } from 'antd';
import APIRequest from '@/lib/APIRequest';
import { AuthOptions } from '@aws-amplify/auth/lib-esm/types/Auth';
import Cookies from 'js-cookie'

interface ICognitoConfiguration {
  region: string;
  userPoolId: string;
  userPoolWebClientId: string;
  domain: string;
  expires: number;
  secure: boolean;
}

/**
 * Authentication Context
 */
const AuthContext = createContext<AuthContextType>({
  loading: true,
  isSignedIn: false,
  checkUserHasGroup: _.constant(false),
  getCredentials: _.constant(undefined),
  authConfig: {},
  sellerAppUrl: "",
  onRefreshUser: _.constant(undefined),
});
AuthContext.displayName = 'AuthContext';

interface AuthContextType {
  user?: User;
  getCredentials: () => Promise<UserCredentials>;
  onStatusChanged?: (state: string) => Promise<string>;
  signout?: () => Promise<void>;
  loading: boolean;
  isSignedIn: boolean;
  checkUserHasGroup: (...arg0: (string | RoleType)[]) => boolean;
  authConfig: AuthOptions;
  sellerAppUrl: string;
  onRefreshUser: () => Promise<void>;
}

/**
 * React hook to process authentication lifecycle
 */

interface AuthProviderInternalState {
  configLoading: boolean;
  Auth?: AuthClass;
  config?: AuthOptions;
  user?: User;
  userLoading: boolean;
  sellerAppUrl?: string;
}

interface AuthProviderMethods {
  onLoadedConfig: (config: AuthOptions) => AuthProviderInternalState;
  onLoadedUserCreds: (user: User) => AuthProviderInternalState;
  onLoadedSellerAppUrl: (sellerAppUrl: string) => AuthProviderInternalState;
}

function createProviderMethods(state: AuthProviderInternalState): AuthProviderMethods {
  function onLoadedConfig(config: AuthOptions): AuthProviderInternalState {
    return { ...state, Auth: new AuthClass(config), config, configLoading: false };
  }
  function onLoadedUserCreds(user: User): AuthProviderInternalState {
    return { ...state, userLoading: false, user };
  }
  function onLoadedSellerAppUrl(sellerAppUrl: string): AuthProviderInternalState {
    return {...state, sellerAppUrl}
  }
  return {
    onLoadedConfig,
    onLoadedUserCreds,
    onLoadedSellerAppUrl
  };
}

interface ICookieStorageData {
  domain: string;
  expires: Date;
  secure: boolean;
}

//TODO: remove this implementation once AWS fix the IE/Edge issue
class CustomCookieStorage {

  constructor(cookieConfig: ICookieStorageData){  
    
    if (typeof document !== 'undefined') {
      if (window.navigator.userAgent.indexOf('Edge') != -1) {
          if (cookieConfig.domain == 'localhost' || cookieConfig.domain == '127.0.0.1')
              delete cookieConfig.domain;
      }
    }
    Cookies.defaults = {
      ...cookieConfig,
      sameSite: 'lax'
    }
  }

  public setItem(key: string, value: string): void{
    Cookies.set(key, value);
  }

  public getItem(key: string): string{
    return Cookies.get(key);
  }

  public removeItem (key: string): void{
    Cookies.remove(key);
  }

  public clear():void{
    Object
    .keys(Cookies.get())
    .forEach(key => Cookies.remove(key))
  }
}

function constructCognito(param: ICognitoConfiguration): AuthOptions {
  const cookieConfig = {
    domain: param.domain,
    path: '/',
    expires: moment().add(param.expires, 'days').toDate(), //number of days
    secure: param.secure
  }
  
  return {
    userPoolId: param.userPoolId,
    userPoolWebClientId: param.userPoolWebClientId,
    region: param.region,
    mandatorySignIn: true,
    authenticationFlowType: 'USER_SRP_AUTH',
    storage: new CustomCookieStorage(cookieConfig) //AWS has bug on IE/Edge for cookie implementation   
  };
}

function useProvideAuth(): AuthContextType {
  const [internal, reducers] = useMethods(createProviderMethods, { userLoading: true, configLoading: true });

  const fetchAuthConfig = async (): Promise<void> => {
    const config = await new APIRequest().setAPI('/user/config').fetchData<ICognitoConfiguration>();
    reducers.onLoadedConfig(constructCognito(config));
  };  
  const fetchUserInfo = async (): Promise<void> => {
    if (!internal.configLoading) {
      const cogUser = await internal.Auth.currentUserInfo();
      if (cogUser) {
        const groups = (await internal.Auth.currentAuthenticatedUser())?.signInUserSession?.accessToken?.payload?.['cognito:groups'] || [];
        const user = Object.assign(new User(), cogUser, { groups });
        reducers.onLoadedUserCreds(user);
      } else reducers.onLoadedUserCreds(undefined);
    }
  };
  const fetchUserCredentials = async (): Promise<UserCredentials> => {
    if (!internal.configLoading) return await internal.Auth.currentSession();
  };
  const fetchSellerAppUrl = async(): Promise<void> => {
    const data = await new APIRequest().setAPI('/common/config/seller-app-url').fetchData<any>();
    reducers.onLoadedSellerAppUrl(data.url);
  };
  const executeSignout = async (): Promise<void> => internal.Auth.signOut();

  useAsync(fetchAuthConfig);
  useEffect((): void => {
    fetchUserInfo();
    fetchSellerAppUrl();
  }, [internal.configLoading]);

  const onAuthStateUpdated = async (newState?: string): Promise<string> => {
    await fetchUserInfo();
    return newState;
  };

  const onRefreshUser = async (): Promise<void> => {
    return new Promise(async (resolve, reject)=>{
       if (!internal.configLoading) {
        const cogUser = await internal.Auth.currentUserInfo();
        if (cogUser) {
          const currentUser: CognitoUser = (await internal.Auth.currentAuthenticatedUser());
          currentUser
            .refreshSession(currentUser.getSignInUserSession().getRefreshToken(), async (error)=>{
              //TODO: update error handling
              if(error)
                return reject(error);

              await fetchUserInfo();
              resolve();
            });  
        } 
        else {
          reducers.onLoadedUserCreds(undefined);
          resolve();
        };
      }
    });
   
  }

  const checkHasGroup = useCallback(
    (...requiredGroups: (string | RoleType)[]): boolean => {
      const group = internal?.user?.groups;
      if (!group) return false;
      return !_(requiredGroups)
        .intersection(group)
        .isEmpty();
    },
    [internal.user]
  );

  return {
    checkUserHasGroup: checkHasGroup,
    isSignedIn: !!internal.user?.signedIn,
    loading: internal.userLoading,
    user: internal.user,
    getCredentials: fetchUserCredentials,
    onStatusChanged: onAuthStateUpdated,
    signout: executeSignout,
    authConfig: internal.config,
    sellerAppUrl: internal.sellerAppUrl,
    onRefreshUser
  };
}

type ProvideAuthProps = PropsWithChildren<{}>;

/**
 * Authentication Provider
 * @param ChildrenProps
 */
export const ProvideAuth: React.FunctionComponent<ProvideAuthProps> = ({ children }: ProvideAuthProps): JSX.Element => {
  const auth = useProvideAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

/**
 * React hook provide access to auth provider
 */
export const useAuth = (): AuthContextType => {
  return useContext(AuthContext);
};

/**
 * Redirect user to auth page based on login status
 * Blocks render before
 * @param WrappedComponent
 */
export function pageRequireAuth<T>(WrappedComponent: NextPage<T>, groups: RoleType[] = [], redirectImmediately?: boolean): NextPage<T> {
  // Try to create a nice displayName for React Dev Tools.
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  // Creating the inner component. The calculated Props type here is the where the magic happens.
  class ComponentWithAuth extends React.Component<T> {
    public static displayName = `requireAuth(${displayName})`;
    static contextType = AuthContext;
    context: AuthContextType;

    public render(): JSX.Element {
      // this.props comes afterwards so the can override the default ones.
      if (!this.context.authConfig) return <>Loading config</>;
      if (this.context.loading) return <Spin />;
      if (!this.context.isSignedIn) {
        if (redirectImmediately === true) {
          Router.push('/auth');
          return <Spin />;
        }
        return <NotSignedInError />;
      }

      if ((!_.isEmpty(groups) && !this.context.checkUserHasGroup(...groups))) {
        return <AccessDeniedError />;
      }

      // Redirect to seller app when user group contains Seller/SellerViewer
      if(this.context.checkUserHasGroup(...SellerUserRoles)) {
        window.location.href = this.context.sellerAppUrl;
      }      

      return <WrappedComponent {...(this.props as T)} />;
    }
  }

  if (WrappedComponent.getInitialProps) {
    return class ComponentWithAuthWithInit extends ComponentWithAuth {
      static async getInitialProps(ctx: NextPageContext): Promise<T> {
        // Check if Page has a `getInitialProps`; if so, call it.
        if (WrappedComponent.getInitialProps) {
          const pageProps = WrappedComponent.getInitialProps && (await WrappedComponent.getInitialProps(ctx));
          // Return props.
          return { ...pageProps };
        }
      }
    };
  }

  return ComponentWithAuth;
}
