import React from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';

const LocaleSelector: React.FunctionComponent = () => {
  const { i18n } = useTranslation();
  const { Option } = Select;
  const langMapping = {
    'en': 'English',
    'zh-CN': '简体中文',
    'zh-HK': '繁體中文',
    'zh-TW': '繁體中文'
  }
  function handleLanguageChange(value: 'zh-CN' | 'zh-HK'): void {
    i18n.changeLanguage(value);
  }

  return (
    <>
      <Select onChange={handleLanguageChange} style={{ width: '100%' }} defaultValue={langMapping[i18n.language] ? langMapping[i18n.language] : 'zh-CN'}>
        <Option value={'zh-CN'}>简体中文</Option>
        <Option value={'zh-HK'}>繁體中文</Option>
      </Select>
    </>
  );
};

export default LocaleSelector;
