import React from 'react';
import { Button, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import Link from 'next/link';
import _ from 'lodash';

interface ErrorProps {
  statusCode: number;
  title?: string;
  message: string;
  detail?: string;
  hideRedirectButton?: boolean;
  redirectUrl?: string;
}

const Error: React.FunctionComponent<ErrorProps> = ({ statusCode, title, message, detail, hideRedirectButton = false, redirectUrl = '/' }: ErrorProps) => {
  const { t } = useTranslation();
  return (
    <>
      <Row className='error-container'>
        <Col span={12} className='error-image__wrapper'>
          <img src='https://gw.alipayobjects.com/zos/rmsportal/KpnpchXsobRgLElEozzI.svg' />
        </Col>
        <Col span={12} className='error-content'>
          <Row>
            <span className='error-content__title'>{title || t('error.common')}</span>
          </Row>
          <Row>
            <span className='error-content__message'>{message}</span>
          </Row>
          {_.isNil(detail) ? null : (
            <Row>
              <pre className='error-content__detail'>{detail}</pre>
            </Row>
          )}
          {hideRedirectButton ? null : (
            <Row>
              <Link href={redirectUrl}>
                <Button>{t('common.back')}</Button>
              </Link>
            </Row>
          )}
          <Row>
            <span className='error-content__pleasecontact'>{t('common.operation.error')}</span>
          </Row>
        </Col>
      </Row>

      <style jsx global>
        {`
          .error-container {
            min-width: 60vw;
            padding-top: 10vh;
          }

          .error-image__wrapper {
            display: flex;
            justify-content: center;
          }

          .error-content {
            align-self: center;
          }

          .error-content__title {
            font-weight: 700;
            font-size: 70px;
          }

          .error-content__message {
            font-size: 24px;
            color: #00000073;
          }

          .error-content__detail {
            font-size: 18px;
            color: #00000073;
            white-space: pre;
            padding-top: 10px;
            margin-bottom: 0px;
          }

          .error-content button {
            background-color: #00b189;
            color: white;
            margin-top: 15px;
          }
        `}
      </style>
    </>
  );
};

export default Error;
