import React from 'react';
import { Layout } from 'antd';
import { CopyrightCircleOutlined } from '@ant-design/icons';
import { LayoutProps } from 'antd/lib/layout';

const { Footer } = Layout;

const QupitalFooter: React.FunctionComponent<LayoutProps> = (props: LayoutProps) => (
  <>
    <Footer {...props}>
      Copyright <CopyrightCircleOutlined /> {new Date().getFullYear()} Qupital Limited 桥彼道有限公司. All rights reserved.
    </Footer>
    <style jsx global>
      {`
        .ant-layout-footer {
          left: 0;
          bottom: 0;
          width: 100%;
          text-align: center;
          color: #00000073;
          opacity: 1;
          padding: 15px;
        }
      `}
    </style>
  </>
);
export default QupitalFooter;
