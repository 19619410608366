export enum Role {
  EXPRESS_APPLICANT = 'expressApplicant',
  SELLER_APPLICANT = 'sellerApplicant',
  POS_APPLICANT = 'posApplicant',
  SELLER = 'seller',
  SELLER_VIEWER = 'sellerViewer',
  SALES = 'sales',
  ACCOUNT_MANAGER = 'accountManager',
  CREDIT_ANALYST = 'creditAnalyst',
  VIEWER = 'viewer',
  MASTER = 'master',
}
