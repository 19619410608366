import React from 'react';
import { ErrorProps } from 'next/error';
import Base from '@components/layout/base/Base';
import Error from '@components/error/Error';
import { useTranslation } from 'react-i18next';

export interface BaseErrorProps extends ErrorProps {
  redirectUrl?: string;
}

export const BaseError: React.FunctionComponent<BaseErrorProps> = ({ title, statusCode, redirectUrl }: BaseErrorProps) => {
  const { t } = useTranslation();
  const defaultMessage = (title: string, statusCode: number): string => {
    if (title) return title;
    switch (statusCode) {
      case 404:
        return t('common.error.pagenotfound');
      case 403:
        return t('common.error.accessdenied');
      default:
        return t('common.error.unknown');
    }
  };

  return (
    <Base>
      <Error statusCode={statusCode} title={statusCode ? `${statusCode}` : undefined} message={defaultMessage(title, statusCode)} redirectUrl={redirectUrl} />
    </Base>
  );
};
