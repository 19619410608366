import React from 'react';
import { Layout } from 'antd';
import Header from '@components/layout/header/Header';
import Footer from '@components/layout/footer/Footer';

const { Content } = Layout;

interface BaseProps {
  children?: React.ReactNode;
  hideHeaderButtons?: boolean;
}

const Base: React.FunctionComponent<BaseProps> = ({ children, hideHeaderButtons = false }: BaseProps): JSX.Element => {
  return (
    <>
      <Layout className='base-layout'>
        <Header hideButtons={hideHeaderButtons} />
        <Content className='main-content'>
          <div className='content-container'>{children}</div>
        </Content>
        <Footer />
      </Layout>
      <style jsx global>
        {`
          .base-layout {
            top: 0px;
            left: 0px;
            position: absolute;
            min-height: 100%;
            min-width: 100%;
          }

          .main-content {
            width: 100%;
            position: relative;
            display: flex;
            justify-content: center;
            background-color: #f2f0f5;
            overflow: auto;
          }

          .content-container {
            padding-top: 50px;
            min-width: 80vw;
            height: 100%;
            display: flex;
            justify-content: center;
          }
        `}
      </style>
    </>
  );
};

export default Base;
