import { Role } from '@common/seller-application/enum/role.enum';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
export interface UserAttributes extends Record<string, string | boolean> {
  sub: string;
  email: string;
  email_verified: boolean;
  phone_number: string;
  phone_number_verified: boolean;
}

export class User {
  id?: string;
  username: string;
  attributes: UserAttributes;
  groups: string[];

  get signedIn(): boolean {
    return !!this.username;
  }
}

interface SessionPayload extends Record<string, any> {
  sub: string;
}

export type UserCredentials = CognitoUserSession;

export const PowerUserRoles: Role[] = [Role.SALES, Role.ACCOUNT_MANAGER, Role.CREDIT_ANALYST, Role.VIEWER, Role.MASTER];
export const SellerUserRoles: Role[] = [Role.SELLER, Role.SELLER_VIEWER];
export const SellerApplicantRoles = [Role.SELLER_APPLICANT];
export const ExpressApplicantRoles = [Role.EXPRESS_APPLICANT];
export const PosApplicantRoles = [Role.POS_APPLICANT];
export type RoleType = Role;
