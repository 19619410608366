import React, { useState, useEffect } from 'react';
import { Col, Menu, Modal, Row } from 'antd';
import { ClickParam } from 'antd/lib/menu';
import { HomeOutlined, LogoutOutlined, MessageOutlined, PhoneOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';

import { useAuth } from '@/hooks/use-auth';
import qydLogo from '@/static/logo/logo_qyd.svg';
import qupitalLogo from '@/static/logo/logo_qupital.svg'
import wechatQRCode from '@/static/images/qupital-wechat-qrcode.jpg';
import { ASSET_URL_XQD_FAQ } from '@/lib/constants/assets';
import { useRouteControl } from '@components/RouteControl';
import { toPairs } from 'lodash';
import { Role } from '@common/seller-application/enum/role.enum';
import LocaleSelector from '@components/i18n/LocaleSelector';

interface HeaderProps {
  hideButtons?: boolean;
}

const Header: React.FunctionComponent<HeaderProps> = ({ hideButtons = false }: HeaderProps): JSX.Element => {
  const { signout, user } = useAuth();
  const router = useRouteControl();
  const { t } = useTranslation();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [manuLogo, setManuLogo] = useState(qydLogo);
  const [isPos, setIsPos] = useState(false);

  const menuItemHandler = async ({ key, keyPath, item, domEvent }: ClickParam): Promise<void> => {
    if (key === 'contactUs') {
      setIsModalVisible(true);
    } else if (key === 'faq') {
      window.open(ASSET_URL_XQD_FAQ, '_blank');
    } else if (key === 'home') {
      await router.home();
    } else if (key === 'logout') {
      await signout();
      await router.auth();
    }
  };

  useEffect((): void => {
    if (user?.groups.find(role => role === Role.POS_APPLICANT)) {
      setManuLogo(qupitalLogo);
      setIsPos(true);
    }
  }, [user])

  return (
    <>
      <Menu className='main-menu' mode='horizontal' onClick={({ key, keyPath, item, domEvent }) => menuItemHandler({ key, keyPath, item, domEvent })}>
        {hideButtons ? null : (
          <Menu.ItemGroup className='main-menu-item-group'>
            <LocaleSelector></LocaleSelector>
            <Menu.Item key='contactUs'>
              <PhoneOutlined className='main-menu-icon' />
              {t('main-menu.contact-us')}
            </Menu.Item>
            {!isPos && <Menu.Item key='faq'>
              <MessageOutlined className='main-menu-icon' /> {t('main-menu.faq')}
            </Menu.Item>}
            <Menu.Divider style={{ height: '2em', width: '2px' }} />
            <Menu.Item key='home'>
              <HomeOutlined className='main-menu-icon' /> {t('main-menu.home')}
            </Menu.Item>
            <Menu.Item key='logout'>
              <LogoutOutlined className='main-menu-icon' />
              {t('main-menu.logout')}
            </Menu.Item>
          </Menu.ItemGroup>
        )}
      </Menu>
      <Modal
        title={t('main-menu.contact-us')}
        footer={null}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        className='main-menu-contact-us-modal'>
        <Row>
          <Col span={10}>
            <Row>{t('main-menu.contact-us.phone')}</Row>
          </Col>
          <Col span={14}>
            {isPos && <Row>(852) 39053333</Row>}
            <Row>(86) 14715485887</Row>
            <Row>(86) 14714936537</Row>
          </Col>
        </Row>
        <Row>
          <Col span={10}>
            <Row>{t('main-menu.contact-us.email')}</Row>
          </Col>
          <Col span={14}>
            <Row>cs@qiaoyidai.com</Row>
          </Col>
        </Row>
        {isPos && <Row>
          <Col span={10}>
            <Row>{t('main-menu.contact-us.quantumEmail')}</Row>
          </Col>
          <Col span={14}>
            <Row>customerservice@qupital.com</Row>
          </Col>
        </Row>}
        <Row>
          <Col span={10}>
            <Row>{t('main-menu.contact-us.wechat')}</Row>
          </Col>
          <Col span={14}>
            <Row>Qupital_HK</Row>
            <Row>
              <img src={wechatQRCode} style={{ position: 'relative', left: '-10px' }} />
            </Row>
          </Col>
        </Row>
      </Modal>

      <style jsx global>
        {`
          .main-menu {
            top: 0px;
            left: 0px;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            height: 100px;
            width: 100%;
            box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
          }

          .main-menu::after {
            position: absolute;
            z-index: 1;
            top: 0px;
            left: 0px;
            display: block;
            height: 80px;
            width: 8vw;
            background: url(${manuLogo}) no-repeat center;
            background-size: contain;
            margin: 10px 0px 10px 40px;
          }

          .main-menu-item-group {
            display: block;
            align-self: center;
          }

          .main-menu-item-group > .ant-menu-item-group-title {
            padding: 0px;
          }

          .ant-menu-item-group-list {
            display: flex;
            align-items: center;
            padding-right: 2vw !important;
          }

          .ant-menu-item-group-list > li {
            color: #000000a6;
            font-family: Segoe UI;
            letter-spacing: 0;
          }

          .ant-menu-item {
            padding: 0px 16px !important;
          }

          .main-menu-icon {
            height: 18px;
            width: 18px;
            color: #00b189;
            margin-right: 2px !important;
          }

          .main-menu-contact-us-modal {
            font-weight: 500;
            width: 400px !important;
          }

          .main-menu-contact-us-modal .ant-modal-body > .ant-row {
            padding-bottom: 30px;
          }
        `}
      </style>
    </>
  );
};

export default Header;
